<template>
    <div class="indexbox" ref="bo1" v-if="indexData.info">
        <div class="box_row" style=" margin-bottom: 8px;">
            <div class="indexBoxLeft">
                <div class="realtime">
                    <div class="realtimetop">
                        <p class="title">数据统计</p>
                        <p class="timeTxt">更新时间：{{ nowDate }}</p>
                    </div>
                    <div class="realtimebot">
                        <div class="listItem">
                            <div class="topItem" v-for="(item, index) in realData.slice(0, 6)" :key="index">
                                <div class="today">
                                    <p>{{ item.data }}</p>
                                    <p>{{ item.name }}
                                        <el-popover placement="top-start" :title="item.name + item.title" width="200"
                                            trigger="hover" :content="item.content">
                                            <i style="font-size:16px;" class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                    </p>
                                    <el-button type="text" style="color:#409EFF;"
                                        @click="$router.push(item.path)">更多</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="listItem">
                            <div class="topItem" v-for="(item, index) in realData.slice(6, 12)" :key="index">
                                <div class="today">
                                    <p>{{ item.data }}</p>
                                    <p>{{ item.name }}
                                        <el-popover placement="top-start" :title="item.name + item.title" width="200"
                                            trigger="hover" :content="item.content">
                                            <i style="font-size:16px;" class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                    </p>
                                    <el-button type="text" style="color:#409EFF;"
                                        @click="$router.push(item.path)">更多</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="listItem">
                            <div class="topItem" v-for="(item, index) in realData.slice(12)" :key="index"
                                @click="$router.push(item.path)" style="cursor: pointer;">
                                <div class="today">
                                    <p>{{ item.data }}</p>
                                    <p style="margin-bottom:12px;">{{ item.name }}
                                        <el-popover placement="top-start" :title="item.name + item.title" width="200"
                                            trigger="hover" :content="item.content">
                                            <i style="font-size:16px;" class="el-icon-question" slot="reference"></i>
                                        </el-popover>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="indexBoxRight">
                <div class="righttop">
                    <div class="righttopT">
                        <div class="titleBox">
                            <div class="title">消息通知</div>
                            <div class="ignoreall" @click="ignoreall">全部忽略</div>
                        </div>
                        <div class="listBox" style="display: flex; justify-content: center; align-items: center"
                            v-if="indexData.message.length == 0">
                            <img :src="imgH + 'msgnull.png'" alt="" />
                        </div>
                        <div v-else class="listBox">
                            <div class="itemBox" v-for="(item, index) in indexData.message" :key="index">
                                <div class="content">
                                    <span></span>
                                    {{ item.content }}
                                </div>
                                <div class="time">{{ item.create_time | setTiem() }}</div>
                                <div class="todeal" @click="toDealMsg(item, index)">去处理</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="charts">
            <div class="realtimetop">
                <p class="title">订单及金额整体数据报表
                    <span>{{ form.search_type == 'service' ? '服务' : '商品' }}</span>
                    <i class="el-icon-sort" @click="changeSearchType"></i>
                </p>
            </div>
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="身份选择" v-if="form.search_type == 'service'">
                    <el-select v-model="form.identity" placeholder="请选择身份">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="子公司" value="1"></el-option>
                        <el-option label="物业" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" v-if="form.search_type == 'service'">
                    <el-input v-model="form.keyword" placeholder="请输入子公司或物业名称"></el-input>
                </el-form-item>
                <el-form-item label="数据选择">
                    <el-select v-model="form.amount_type">
                        <el-option :label="form.search_type == 'service' ? item.name1 : item.name2" :value="item.val"
                            v-for="item in amount_type" :key="item.val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户选择">
                    <el-select v-model="form.user_type" placeholder="请选择身份">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="VIP" value="1"></el-option>
                        <el-option label="非VIP" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="日期级别">
                    <el-select v-model="form.date_type" placeholder="请选择身份">
                        <el-option label="月" value="month"></el-option>
                        <el-option label="年" value="year"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getDateData()">搜索</el-button>
                    <el-button type="text" @click="cancelSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <div id="eBox" ref="eBox"></div>
        </div>
    </div>
</template>
  
<script>
import { getDateformat } from '@/util/getDate';
import { imgUrlHead } from '@/util/config';
import * as echarts from 'echarts';
import _ from 'lodash';
export default {
    data () {
        return {
            indexData: {},
            nowDate: null,
            imgH: imgUrlHead,
            option: {},
            myChart: null,
            realData: [],
            form: {
                identity: '',
                keyword: '',
                amount_type: 'total_price',
                user_type: '',
                date_type: 'month',
                search_type: 'service'
            },
            amount_type: [
                {
                    val: 'total_price',
                    name1: '总金额',
                    name2: '总金额',
                    color: '#00C164'
                },
                {
                    val: 'deposit_price',
                    name1: '定金金额',
                    name2: '实付金额',
                    color: '#F86200'
                },
                {
                    val: 'final_pay_price',
                    name1: '尾款',
                    name2: '使用余额情况',
                    color: '#F33C3F'
                },
                {
                    val: 'fianl_reduce_price',
                    name1: '优惠券金额',
                    name2: '优惠金额',
                    color: '#FFA301'
                },

            ]
        };
    },
    created () {
        this.nowDate = getDateformat(new Date().getTime() / 1000);
        this.getIndexData();
        this.getIndexData2();
    },
    methods: {
        // 开启在线客服
        onLineCustom () {
            window.open('https://mpkf.weixin.qq.com/');
        },
        // 点击去处理消息通知
        toDealMsg (item, index) {
            if (item.type == 6) {
                this.$message({
                    message: '暂未开放客服模块',
                    type: 'warning',
                });
                return;
            }
            let path = '';
            let that = this;
            switch (item.type) {
                case 1:
                    path = '/repair/goods/orderlist?tab_type=state_pay';
                    break;
                case 2:
                    path = '/repair/goods/salelist?type=1';
                    break;
                case 3:
                    path = `/store/storeManagement?id=${item.store_id}`;
                    break;
                case 4:
                    path = `/store/reflectAudit?id=${item.store_id}`;
                    break;
                case 5:
                    path = '/set/weixin?from=1';
                    break;
                case 6:
                    path = '';
                    break;
                case 7:
                    path = '/repair/goods/evaluate';
                    break;
                case 8:
                    path = '/user/userList';
                    break;
                case 9:
                    path = '/user/userList';
                    break;
                case 10:
                    path = '/user/userList';
                    break;
                case 50:
                    path = '/user/repairSettledAuditing';
                    break;
                case 51:
                    path = '/user/repairSettledList';
                    break;
                case 52:
                    path = '/user/repairSettledList';
                    break;
                case 53:
                    path = '/user/repairPartnerReview';
                    break;
                case 54:
                    path = '/user/repairStoreAuditList';
                    break;
                case 55:
                    path = '/repair/goods/salelist';
                    break;
                case 56:
                    path = '/repair/finance/depositRefund';
                    break;
                case 57:
                    path = '/repair/finance/artisanWithdraw';
                    break;
                case 58:
                    path = '/repair/finance/storeWithdraw';
                    break;
                case 59:
                    path = '/repair/finance/accounts';
                    break;
                case 60:
                    path = '/marketing/repairAdvertisingReview';
                    break;
                default:
                    break;
            }
            this.$axios.post(this.$api.homeIndex.readMsg, {
                message_id: item.id,
                message_type: 3,
            });
            this.$emit('removeMsg');
            this.indexData.message.splice(index, 1);
            this.$router.push(path);
        },
        // 获取首页数据
        getIndexData () {
            this.$axios.post(this.$api.homeIndex.indexData, {}).then(res => {
                if (res.code == 0) {
                    let result = res.result;
                    this.indexData = result;
                    localStorage.setItem('msgLength', String(this.indexData.message.length));
                    this.getDateData()
                }
            });
        },
        getIndexData2 () {
            this.$axios.post(this.$api.repair.index.getData).then(res => {
                if (res.code == 0) {
                    let result = res.result;
                    this.realData = [
                        {
                            name: '商品总金额',
                            data: result.goods_amount,
                            path: '/repair/finance/goodsIncome',
                            title: '(元)',
                            content: '每笔提交订单的商品原价总计'
                        },
                        {
                            name: '商品订单总金额',
                            data: result.order_amount,
                            path: '/repair/finance/goodsIncome',
                            title: '(元)',
                            content: '每笔订单用户总实际支付和运费或配送费的总计（总实际支付包含有余额支付的金额）'
                        },
                        {
                            name: '商品结算总金额',
                            data: result.goods_settlement,
                            path: '/repair/finance/goodsIncome',
                            title: '(元)',
                            content: '按每笔订单的结算算法：给商家总结算金额'
                        },
                        {
                            name: '商品折扣总金额',
                            data: result.deduct_amount,
                            path: '/repair/finance/goodsIncome',
                            title: '(元)',
                            content: '有使用折扣券的订单的折扣金额总计'
                        },
                        {
                            name: '商品佣金总金额',
                            data: result.goods_commission,
                            path: '/repair/finance/goodsIncome',
                            title: '(元)',
                            content: '每笔订单平台获得的佣金总计'
                        },
                        {
                            name: '商品总订单数',
                            data: result.order_nums,
                            path: '/repair/finance/goodsIncome',
                            title: '(笔)',
                            content: '所有已付款的订单数量总计'
                        },
                        {
                            name: '服务总金额',
                            data: result.service_amount,
                            path: '/repair/finance/serveIncome',
                            title: '(元)',
                            content: '每笔维修订单的维修原价总计'
                        },
                        {
                            name: '服务订单总金额',
                            data: result.service_order_amount,
                            path: '/repair/finance/serveIncome',
                            title: '(元)',
                            content: '每笔订单用户总实际支付的总计（总实际包含有余额支付的金额）'
                        },
                        {
                            name: '服务结算总金额',
                            data: result.service_settlement,
                            path: '/repair/finance/serveIncome',
                            title: '(元)',
                            content: '按每笔订单的结算算法：给物业或技工总结算金额'
                        },
                        {
                            name: '服务折扣总金额',
                            data: result.service_deduct_amount,
                            path: '/repair/finance/serveIncome',
                            title: '(元)',
                            content: '有使用折扣券的维修订单的折扣金额总计'
                        },
                        {
                            name: '服务佣金总金额',
                            data: result.service_commission,
                            path: '/repair/finance/serveIncome',
                            title: '(元)',
                            content: '每笔维修订单平台获得的佣金总计'
                        },
                        {
                            name: '服务总订单数',
                            data: result.service_order_nums,
                            path: '/repair/finance/serveIncome',
                            title: '(笔)',
                            content: '所有已付尾款的维修订单数量总计（包含VIP不用付尾款订单）'
                        },
                        {
                            name: '子公司总数',
                            data: result.subsidiary,
                            title: '(个)',
                            content: '平台添加的全部子公司总计',
                            path: '/user/repairSubsidiaryList',
                        },
                        {
                            name: '物业总数',
                            data: result.property,
                            title: '(个)',
                            content: '平台添加的全部物业总计',
                            path: '/user/repairPropertyList',
                        },
                        {
                            name: '合伙人总数',
                            data: result.partner,
                            title: '(人)',
                            content: '平台入驻的全部合伙人总计',
                            path: '/user/repairPartnerList',
                        },
                        {
                            name: '商家总数',
                            data: result.store,
                            title: '(人)',
                            content: '平台入驻的全部商家总计',
                            path: '/user/repairStoreList',
                        },
                        {
                            name: '技工总数',
                            data: result.artisan,
                            title: '(人)',
                            content: '平台添加和入驻的全部技工总计',
                            path: '/user/repairSettledList',
                        },
                        {
                            name: 'VIP用户',
                            data: result.vip,
                            title: '总数(人)',
                            content: '平台的所有VIP用户总计',
                            path: '/user/repairUserList',
                        },
                        {
                            name: '非VIP用户',
                            data: result.no_vip_user,
                            title: '总数(人)',
                            content: '平台的所有非VIP用户总计',
                            path: '/user/repairUserList',
                        },
                    ];
                }
            });
        },
        // 消息全部忽略
        ignoreall () {
            if (this.indexData.message.length == 0) {
                this.$message.warning('暂无待处理消息');
                return;
            }
            let that = this;
            this.$axios
                .post(this.$api.homeIndex.readMsg, {
                    message_id: 'all',
                    message_type: 3,
                })
                .then(res => {
                    if (res.code == 0) {
                        that.$message.success('处理成功');
                        that.$emit('removeMsg', 1, that.indexData.message.length);
                        that.indexData.message = [];
                    } else {
                        that.$message.error('处理失败');
                    }
                });
        },
        cancelSearch () {
            this.form = {
                identity: '',
                keyword: '',
                amount_type: 'total_price',
                user_type: '',
                date_type: 'month',
                search_type: 'service'
            }
            this.getDateData()
        },
        getDateData () {
            this.$axios
                .post(this.$api.repair.index.getDateData, this.form)
                .then(res => {
                    if (res.code == 0) {
                        let { result } = res
                        let dataDate = []
                        let dataData = []
                        if (this.form.date_type == 'month') {
                            dataDate = this.indexData.info.getEveryDayData.date
                            dataData = result
                        } else {
                            dataDate = result.map(item => item.date + '月')
                            dataData = result.map(item => item.data)
                        }
                        let obj = this.amount_type.find(item => item.val == this.form.amount_type)
                        let name = this.form.search_type == 'service' ? obj.name1 : obj.name2
                        let color = obj.color
                        if (!this.myChart) {
                            let box = document.getElementById('eBox');
                            this.myChart = echarts.init(box);
                        }

                        let option = {
                            color: [color],
                            title: {},
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    label: {
                                        backgroundColor: '#6a7985',
                                    },
                                },
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true,
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    boundaryGap: false,
                                    data: dataDate
                                },
                            ],
                            yAxis: [
                                {
                                    type: 'value',
                                },
                            ],
                            series: [
                                {
                                    name: name,
                                    type: 'line',
                                    smooth: true,
                                    lineStyle: {
                                        width: 3,
                                    },
                                    showSymbol: false,
                                    areaStyle: {
                                        opacity: 0.1,
                                        color: color,
                                        origin: 'start',
                                    },
                                    data: dataData
                                },
                            ],
                        };
                        this.option = option;
                        option && this.myChart.setOption(this.option);
                        //window.clearInterval(timer);
                        //let that = this;
                        //let timer = setInterval(() => {
                        //}, 1000);
                    } else {
                        this.$message.error('处理失败');
                    }
                });
        },
        changeSearchType () {
            this.form = {
                identity: '',
                keyword: '',
                amount_type: 'total_price',
                user_type: '',
                date_type: 'month',
                search_type: this.form.search_type == 'service' ? 'goods' : 'service'
            }
            this.getDateData()
        }
    },
    filters: {
        setTiem (index) {
            return getDateformat(index);
        },
    },
};
</script>
  
<style lang="less" scoped>
.indexbox {
    //width: 100%;
    //height: 100%;


    font-size: 14px;

    .box_row {
        display: flex;
        justify-content: space-between;
    }

    .indexBoxLeft {
        flex: 26;
        height: 388px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 8px;
        background-color: #fff;

        .realtime {
            .realtimebot {
                //height: calc(100% - 52px);
                padding: 0 30px 23px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .listItem {
                    display: flex;
                    flex-wrap: nowrap;

                    &+.listItem {
                        margin-top: 20px;
                    }

                    .topItem {
                        flex: 1;
                        flex-shrink: 0;
                        //height: 104px;
                        border: 1px solid #f2f3f5;
                        border-radius: 8px;
                        display: flex;
                        justify-content: space-between;
                        padding: 12px 20px 0;
                        align-items: center;

                        &+.topItem {
                            margin-left: 20px;
                        }

                        .today {
                            text-align: left;

                            p {
                                white-space: nowrap;
                            }

                            p:first-child {
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .indexBoxRight {
        flex: 10;
        min-width: 478px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .righttop {
            height: 388px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 8px;
            background-color: #fff;

            &>div {
                width: 100%;
                // box-shadow: 0px 2px 16px rgba(86, 84, 243, 0.1);
            }

            .righttopT {
                height: 388px;
                padding: 16px 24px 20px 24px;
                margin-bottom: 8px;
                display: flex;
                flex-direction: column;

                .titleBox {
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;

                    .title {
                        font-size: 14px;
                        font-weight: bold;
                        color: #17171a;

                        &:before {
                            content: '';
                            height: 14px;
                            border-right: 3px solid #1467ff;
                            margin-right: 8px;
                        }
                    }

                    .ignoreall {
                        color: #979899;
                        cursor: pointer;
                    }
                }

                .listBox {
                    flex: 1;
                    padding-right: 10px;
                    overflow: hidden;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 5px !important;
                        height: 5px !important;
                    }

                    .itemBox {
                        display: flex;
                        margin-bottom: 12px;
                        justify-content: space-between;
                        align-items: center;

                        .content {
                            width: 192px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #656566;

                            span {
                                display: inline-block;
                                width: 4px;
                                height: 4px;
                                margin-bottom: 2.5px;
                                background: #1467ff;
                                border-radius: 50%;
                                margin-right: 5px;
                            }
                        }

                        .time {
                            color: #cacbcc;
                            font-size: 12px;
                        }

                        .todeal {
                            color: #1467ff;
                            font-size: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.charts {
    background-color: #fff;

    .el-form {
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px;
    }

    #eBox {
        width: 100%;
        min-height: 392px;
    }
}

.realtimetop {
    display: flex;
    align-items: center;
    padding: 16px 32px;

    .title {
        font-weight: bold;
        font-size: 14px;

        &:before {
            content: '';
            height: 14px;
            border-right: 3px solid #1467ff;
            margin-right: 8px;
        }

        span,
        i {
            color: #409EFF;

        }

        span {
            margin: 0 10px;
            font-size: 15px;
        }

        i {
            transform: rotate(90deg);
            font-size: 18px;
            cursor: pointer;
        }
    }

    .timeTxt {
        margin: 0 8px 0 16px;
        color: #969699;
    }
}
</style>
  